import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";

const AnswerFilterButton = props => {
  const { dispatch } = useContext(TestContext);
  const { state } = useContext(TestContext);
  // Button click action filter
  let answerFilter;
  if (props.all === "true") {
    answerFilter = "all";
  } else if (props.preference.show) {
    answerFilter = props.preference.abbreviation;
  } else {
    answerFilter = state.answerFilter;
  }
  // Button label
  const label =
    props.all === "true"
      ? "All Answers"
      : props.preference.preference1 + "/" + props.preference.preference2;
  // Button colors
  let colors = props.preference.show
    ? "text-gray-700 hover:text-white hover:bg-gray-700 border-gray-700 cursor-pointer"
    : "text-gray-300 border-text-gray-300";
  if (state.answerFilter === "all" && props.all === "true") {
    colors = "text-white bg-gray-700 border-gray-700 cursor-default";
  }
  if (state.answerFilter !== "all" && props.all === "true") {
    colors =
      "text-gray-700 hover:text-white hover:bg-gray-700 border-gray-700 cursor-pointer";
  }
  if (props.all !== "true") {
    if (state.answerFilter === props.preference.abbreviation) {
      colors = "text-white bg-gray-700 border-gray-700 cursor-default";
    }
  }
  return (
    <div
      className={
        colors + " font-sans text-sm text-center rounded-full px-2 mx-2 "
      }
      onClick={() => {
        dispatch({
          type: `update answerFilter`,
          filter: answerFilter
        });
      }}
    >
      {label}
    </div>
  );
};

export default AnswerFilterButton;
