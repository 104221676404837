import React from "react";
import "../../node_modules/react-vis/dist/style.css";
import { RadialChart } from "react-vis";

function DonutChart(props) {
  const data = [
    { angle: props.preferences.score1, color: "#C5CAE9" },
    { angle: props.preferences.score2, color: "#303F9F" }
  ];
  const highlighted1 = props.preferences.highlighted1
    ? "font-heading font-bold text-indigo-700"
    : "font-sans text-gray-400";
  const highlighted2 = props.preferences.highlighted2
    ? "font-heading font-bold text-indigo-700"
    : "font-sans text-gray-400";
  return (
    <div>
      <div className="">
        <RadialChart
          data={data}
          width={150}
          height={150}
          innerRadius={38}
          radius={50}
          colorType="literal"
        />
      </div>
      <div className="flex items-center">
        <div className="bg-indigo-100 h-4 px-1">&nbsp;</div>
        <div className={highlighted1 + " w-full text-sm text-center"}>
          {props.preferences.preference1}
        </div>
      </div>
      <div className="flex items-center">
        <div className="bg-indigo-700 h-4 px-1">&nbsp;</div>
        <div className={highlighted2 + " w-full text-sm text-center"}>
          {props.preferences.preference2}
        </div>
      </div>
    </div>
  );
}

export default DonutChart;
