import React from "react";
import QuestionForm from "./QuestionForm";
import TestOver from "./TestOver";
import PreferenceCharts from "./PreferenceCharts";
import TypeIndicators from "./TypeIndicators";
import Answers from "./Answers";
import LearnMore from "./LearnMore";
import Footer from "./Footer";

function TestPage() {
  return (
    <div className="bg-gray-50">
      <div className="lg:flex w-full">
        <QuestionForm />
      </div>
      <div className="lg:flex w-full">
        <TestOver />
      </div>
      <div className="lg:flex w-full">
        <div className="lg:flex w-full lg:w-1/2">
          <PreferenceCharts />
        </div>
        <div className="lg:flex w-full lg:w-1/2">
          <TypeIndicators />
        </div>
      </div>
      <div className="lg:flex w-full">
        <Answers />
      </div>
      <div className="lg:flex w-full">
        <LearnMore />
      </div>
      <div className="lg:flex w-full">
        <Footer />
      </div>
    </div>
  );
}

export default TestPage;
