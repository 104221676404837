import React from "react";

const QuestionAlternative = props => {
  const classes =
    props.location === "left" ? "justify-center" : "justify-center ";
  return (
    <div
      className={
        "flex " +
        classes +
        " items-center w-full text-base font-heading font-bold text-gray-700 py-2 px-2 "
      }
    >
      {props.alternative}
    </div>
  );
};

export default QuestionAlternative;
