import React from "react";

function LearnMore() {
  return (
    <div className="w-full py-16">
      <h2 className="flex w-full text-xl font-heading font-bold justify-center mb-5 text-indigo-700">
        Learn More
      </h2>
      <div className="flex w-full">
        <div className="w-1/2">
          <h3 className="flex w-full text-lg font-heading font-bold justify-center mb-5 text-indigo-700">
            Myers-Briggs Types
          </h3>
          <div className=" text-gray-800 font-regular px-64">
            <div className="w-full">
              <a
                className=""
                href="https://www.16personalities.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                16 Personalities
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.truity.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Truity
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="http://www.humanmetrics.com/personality/type"
                target="_blank"
                rel="noopener noreferrer"
              >
                Humanmetrics
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://en.m.wikipedia.org/wiki/Myers%E2%80%93Briggs_Type_Indicator"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wikipedia
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.verywellmind.com/the-myers-briggs-type-indicator-2795583"
                target="_blank"
                rel="noopener noreferrer"
              >
                Very Well Mind
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.psychologytoday.com/us/blog/give-and-take/201309/goodbye-mbti-the-fad-won-t-die"
                target="_blank"
                rel="noopener noreferrer"
              >
                Psychology Today
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.forbes.com/sites/michaelbarthur/2018/09/16/the-strange-history-behind-the-mbti-and-what-that-can-mean-for-career-owners/#1d09df772fb3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Forbes
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.usatoday.com/story/news/nation/2019/05/06/myers-briggs-type-indicator-does-not-matter/3635592002/"
                target="_blank"
                rel="noopener noreferrer"
              >
                USA Today
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.vice.com/en_us/article/bjv8y5/the-myers-briggs-personality-test-bullshit"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vice
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <h3 className="flex w-full text-lg font-heading font-bold justify-center mb-5 text-indigo-700">
            Celebrity/Fictional Character Types
          </h3>
          <div className=" text-gray-800 font-regular px-64">
            <div className="w-full">
              <a
                className=""
                href="https://personality-database.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Personality Database
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://geekologie.com/2013/12/star-wars-characters-myers-briggs-person.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                Star Wars
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://churchm.ag/lord-of-the-rings-personality-types/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lord of the Rings
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://personalitygrowth.com/myers-briggs-mbti-dc-superhero-character-types/"
                target="_blank"
                rel="noopener noreferrer"
              >
                DC Superheroes
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://ohmy.disney.com/movies/2015/10/18/what-is-your-disney-princess-personality-type/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disney Princesses
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.personalityclub.com/blog/harry-potter-personality-chart/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Harry Potter
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.personalityclub.com/blog/hunger-games-personality-chart/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hunger Games
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://www.quora.com/What-is-the-MBTI-of-the-various-characters-in-the-TV-series-Game-of-Thrones"
                target="_blank"
                rel="noopener noreferrer"
              >
                Game of Thrones
              </a>{" "}
            </div>
            <div className="w-full">
              <a
                className=""
                href="https://churchm.ag/marvel-comic-book-personality-chart/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marvel Comics
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
