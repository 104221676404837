import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import PreferenceChart from "./PreferenceChart";

function PreferenceCharts() {
  const { state } = useContext(TestContext);
  return (
    <div className="pb-3 pl-16 pr-8 pt-10 w-full">
      <h2 className="flex w-full text-xl font-heading font-bold justify-center pb-5 text-indigo-700">
        Preferences
      </h2>
      <div className="w-full ">
        <div className="flex flex-wrap -mx-1">
          <div className="w-full lg:w-1/2 px-1 mb-2">
            <PreferenceChart preferences={state.preferences[0]} />
          </div>
          <div className="w-full lg:w-1/2 px-1 mb-2">
            <PreferenceChart preferences={state.preferences[1]} />
          </div>
          <div className="w-full lg:w-1/2 px-1 mb-2">
            <PreferenceChart preferences={state.preferences[2]} />
          </div>
          <div className="w-full lg:w-1/2 px-1 mb-2">
            <PreferenceChart preferences={state.preferences[3]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreferenceCharts;
