import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import AnswerFilterButton from "./AnswerFilterButton";

const AnswerFilterForm = props => {
  const { state } = useContext(TestContext);
  return (
    <div className="flex w-full pb-3">
      <AnswerFilterButton all="true" preference={state.preferences[0]} />
      <AnswerFilterButton all="false" preference={state.preferences[0]} />
      <AnswerFilterButton all="false" preference={state.preferences[1]} />
      <AnswerFilterButton all="false" preference={state.preferences[2]} />
      <AnswerFilterButton all="false" preference={state.preferences[3]} />
    </div>
  );
};

export default AnswerFilterForm;
