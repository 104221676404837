import React from "react";
import "./css/tailwind.css";

import { TestProvider } from "./context/TestContext";
import TestPage from "./components/TestPage";
import Sidebar from "./components/Sidebar";
import SmallScreen from "./components/SmallScreen";

function App() {
  return (
    <div className="App">
      {/* SMALL SCREENS */}
      <div className="lg:hidden">
        <SmallScreen />
      </div>
      {/* LARGE SCREENS */}
      <div className="hidden lg:block">
        <TestProvider>
          <div className="lg:flex w-full">
            <div className="lg:flex w-1/6 bg-white">
              <Sidebar />
            </div>
            <div className="lg:flex w-5/6">
              <TestPage />
            </div>
          </div>
        </TestProvider>
      </div>
    </div>
  );
}

export default App;
