import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import "../../node_modules/react-vis/dist/style.css";
import { RadialChart } from "react-vis";

function Sidebar() {
  const { state } = useContext(TestContext);
  let unanswered = 32 - state.currentQuestion;
  let answered = state.currentQuestion;
  const data = [
    { angle: unanswered, color: "#C5CAE9" },
    { angle: answered, color: "#303F9F" }
  ];
  let progress;
  if (state.currentQuestion === 0) {
    progress = "No questions answered yet";
  } else if (state.currentQuestion > 31) {
    progress = "Test completed";
  } else progress = state.currentQuestion + " of 32 answered";
  return (
    <div className="fixed w-1/6 pt-6 px-8 font-heading font-regular text-gray-800">
      <h1 className="w-full text-2xl font-heading font-bold text-center text-indigo-700">
        Personality Test
      </h1>
      <div className="text-sm leading-tight text-justify pt-6">
        This test consists of 32 pairs of personal attributes. For each pair,
        you just have to pick which one (if either) applies more to yourself.
        Based on your answers, you will be assigned to one of the sixteen basic
        personality types that were created by Katharine Cook Briggs and her
        daughter Isabel Briggs Myers, based on the theories of Swiss
        psychiatrist Carl Jung.
      </div>
      <div className="flex w-full justify-center pt-8">
        <RadialChart
          data={data}
          width={100}
          height={100}
          innerRadius={30}
          radius={40}
          colorType="literal"
        />
      </div>
      <div className="w-full text-sm text-center">{progress}</div>
      <div className="w-full text-sm leading-tight text-justify pt-10">
        Based on the{" "}
        <a
          className="text-indigo-700"
          href="https://openpsychometrics.org/tests/OEJTS/development/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Extended Jungian Type Scales 1.2
        </a>{" "}
        created by By Eric Jorgenson. The items of the Open Extended Jungian
        Type Scales 1.2 are licenced under a Creative Commons
        Attribution-NonCommercial-ShareAlike 4.0 International License.
      </div>
    </div>
  );
}

export default Sidebar;
