function updateResults(questions) {
  let updatedQuestions = questions;
  questions.forEach(function(question, index) {
    if (question.measure === "IE") {
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            updatedQuestions[index].preference = "Introversion";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 2:
            updatedQuestions[index].preference = "Introversion";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 4:
            updatedQuestions[index].preference = "Extraversion";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 5:
            updatedQuestions[index].preference = "Extraversion";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            updatedQuestions[index].preference = "Introversion";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 4:
            updatedQuestions[index].preference = "Introversion";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 2:
            updatedQuestions[index].preference = "Extraversion";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 1:
            updatedQuestions[index].preference = "Extraversion";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "SN") {
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            updatedQuestions[index].preference = "Sensing";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 2:
            updatedQuestions[index].preference = "Sensing";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 4:
            updatedQuestions[index].preference = "Intuition";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 5:
            updatedQuestions[index].preference = "Intuition";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            updatedQuestions[index].preference = "Sensing";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 4:
            updatedQuestions[index].preference = "Sensing";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 2:
            updatedQuestions[index].preference = "Intuition";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 1:
            updatedQuestions[index].preference = "Intuition";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "FT") {
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            updatedQuestions[index].preference = "Thinking";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 2:
            updatedQuestions[index].preference = "Thinking";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 4:
            updatedQuestions[index].preference = "Feeling";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 5:
            updatedQuestions[index].preference = "Feeling";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            updatedQuestions[index].preference = "Thinking";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 4:
            updatedQuestions[index].preference = "Thinking";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 2:
            updatedQuestions[index].preference = "Feeling";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 1:
            updatedQuestions[index].preference = "Feeling";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "JP") {
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            updatedQuestions[index].preference = "Judging";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 2:
            updatedQuestions[index].preference = "Judging";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 4:
            updatedQuestions[index].preference = "Perception";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 5:
            updatedQuestions[index].preference = "Perception";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            updatedQuestions[index].preference = "Judging";
            updatedQuestions[index].preferenceScore = "2";
            break;
          case 4:
            updatedQuestions[index].preference = "Judging";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 2:
            updatedQuestions[index].preference = "Perception";
            updatedQuestions[index].preferenceScore = "1";
            break;
          case 1:
            updatedQuestions[index].preference = "Perception";
            updatedQuestions[index].preferenceScore = "2";
            break;
          default:
            break;
        }
      }
    }
  });
  return updatedQuestions;
}

export default updateResults;
