import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";

const QuestionFormButton = props => {
  const { dispatch } = useContext(TestContext);
  let description = "";
  switch (props.choice) {
    case "1":
      description = "The one on the left describes me a lot";
      break;
    case "2":
      description = "The one on the left describes me a little";
      break;
    case "3":
      description = "Neither one describes me";
      break;
    case "4":
      description = "The one on the right describes me a little";
      break;
    case "5":
      description = "The one on the right describes me a lot";
      break;
    default:
      break;
  }
  return (
    <div
      className="w-1/5 bg-white hover:bg-gray-500 text-grey-800 hover:text-white font-regular text-sm leading-tight text-center rounded py-4 px-8 mx-2 shadow-md cursor-pointer"
      onClick={() => {
        dispatch({
          type: `update score`,
          score: props.choice
        });
      }}
    >
      {description}
    </div>
  );
};

export default QuestionFormButton;
