function updatePreferences(results) {
  let preferences = [
    {
      preference1: "Introversion",
      preference2: "Extraversion",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "IE",
      show: false
    },
    {
      preference1: "Intuition",
      preference2: "Sensing",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "SN",
      show: false
    },
    {
      preference1: "Feeling",
      preference2: "Thinking",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "FT",
      show: false
    },
    {
      preference1: "Perception",
      preference2: "Judging",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "JP",
      show: false
    }
  ];
  if (results.IE) {
    preferences[0].show = true;
  }
  if (results.SN) {
    preferences[1].show = true;
  }
  if (results.FT) {
    preferences[2].show = true;
  }
  if (results.JP) {
    preferences[3].show = true;
  }
  if (results.I || results.E) {
    preferences[0].score1 = results.I;
    preferences[0].score2 = results.E;
  }
  if (results.I > results.E) {
    preferences[0].highlighted1 = true;
  }
  if (results.E > results.I) {
    preferences[0].highlighted2 = true;
  }
  if (results.S || results.N) {
    preferences[1].score1 = results.N;
    preferences[1].score2 = results.S;
  }
  if (results.N > results.S) {
    preferences[1].highlighted1 = true;
  }
  if (results.S > results.N) {
    preferences[1].highlighted2 = true;
  }
  if (results.F || results.T) {
    preferences[2].score1 = results.F;
    preferences[2].score2 = results.T;
  }
  if (results.F > results.T) {
    preferences[2].highlighted1 = true;
  }
  if (results.T > results.F) {
    preferences[2].highlighted2 = true;
  }
  if (results.P || results.J) {
    preferences[3].score1 = results.P;
    preferences[3].score2 = results.J;
  }
  if (results.P > results.J) {
    preferences[3].highlighted1 = true;
  }
  if (results.J > results.P) {
    preferences[3].highlighted2 = true;
  }
  return preferences;
}

export default updatePreferences;
