import React from "react";

function Score(props) {
  const choice1Color = props.score === 1 ? "bg-indigo-700" : "bg-gray-300";
  const choice2Color = props.score === 2 ? "bg-indigo-700" : "bg-gray-300";
  const choice3Color = props.score === 3 ? "bg-indigo-700" : "bg-gray-300";
  const choice4Color = props.score === 4 ? "bg-indigo-700" : "bg-gray-300";
  const choice5Color = props.score === 5 ? "bg-indigo-700" : "bg-gray-300";
  return (
    <div className=" flex w-full">
      <div className={choice1Color + " h-4 w-4 mx-3 rounded-lg"}>&nbsp;</div>
      <div className={choice2Color + " h-4 w-4 mx-3 rounded-lg"}>&nbsp;</div>
      <div className={choice3Color + " h-4 w-4 mx-3 rounded-lg"}>&nbsp;</div>
      <div className={choice4Color + " h-4 w-4 mx-3 rounded-lg"}>&nbsp;</div>
      <div className={choice5Color + " h-4 w-4 mx-3 rounded-lg"}>&nbsp;</div>
    </div>
  );
}

export default Score;
