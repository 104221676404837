function updateTypes(currentQuestion, results) {
  let types = {
    ISTJ: false,
    ISFJ: false,
    INFJ: false,
    INTJ: false,
    ISTP: false,
    ISFP: false,
    INFP: false,
    INTP: false,
    ESTP: false,
    ESFP: false,
    ENFP: false,
    ENTP: false,
    ESTJ: false,
    ESFJ: false,
    ENFJ: false,
    ENTJ: false
  };

  if (currentQuestion === 0) return types;

  if (
    results.I >= results.E &&
    results.S >= results.N &&
    results.T >= results.F &&
    results.J >= results.P
  ) {
    types.ISTJ = true;
  }

  if (
    results.I >= results.E &&
    results.S >= results.N &&
    results.F >= results.T &&
    results.J >= results.P
  ) {
    types.ISFJ = true;
  }

  if (
    results.I >= results.E &&
    results.N >= results.S &&
    results.F >= results.T &&
    results.J >= results.P
  ) {
    types.INFJ = true;
  }

  if (
    results.I >= results.E &&
    results.N >= results.S &&
    results.T >= results.F &&
    results.J >= results.P
  ) {
    types.INTJ = true;
  }

  if (
    results.I >= results.E &&
    results.S >= results.N &&
    results.T >= results.F &&
    results.P >= results.J
  ) {
    types.ISTP = true;
  }

  if (
    results.I >= results.E &&
    results.S >= results.N &&
    results.F >= results.T &&
    results.P >= results.J
  ) {
    types.ISFP = true;
  }

  if (
    results.I >= results.E &&
    results.N >= results.S &&
    results.F >= results.T &&
    results.P >= results.J
  ) {
    types.INFP = true;
  }

  if (
    results.I >= results.E &&
    results.N >= results.S &&
    results.T >= results.F &&
    results.P >= results.J
  ) {
    types.INTP = true;
  }

  if (
    results.E >= results.I &&
    results.S >= results.N &&
    results.T >= results.F &&
    results.P >= results.J
  ) {
    types.ESTP = true;
  }

  if (
    results.E >= results.I &&
    results.S >= results.N &&
    results.F >= results.T &&
    results.P >= results.J
  ) {
    types.ESFP = true;
  }

  if (
    results.E >= results.I &&
    results.N >= results.S &&
    results.F >= results.T &&
    results.P >= results.J
  ) {
    types.ENFP = true;
  }

  if (
    results.E >= results.I &&
    results.N >= results.S &&
    results.T >= results.F &&
    results.P >= results.J
  ) {
    types.ENTP = true;
  }

  if (
    results.E >= results.I &&
    results.S >= results.N &&
    results.T >= results.F &&
    results.J >= results.P
  ) {
    types.ESTJ = true;
  }

  if (
    results.E >= results.I &&
    results.S >= results.N &&
    results.F >= results.T &&
    results.J >= results.P
  ) {
    types.ESFJ = true;
  }

  if (
    results.E >= results.I &&
    results.N >= results.S &&
    results.F >= results.T &&
    results.J >= results.P
  ) {
    types.ENFJ = true;
  }

  if (
    results.E >= results.I &&
    results.N >= results.S &&
    results.T >= results.F &&
    results.J >= results.P
  ) {
    types.ENTJ = true;
  }

  return types;
}

export default updateTypes;
