function updateResults(questions) {
  let results = {
    I: 0,
    E: 0,
    S: 0,
    N: 0,
    F: 0,
    T: 0,
    J: 0,
    P: 0,
    IE: false,
    SN: false,
    FT: false,
    JP: false
  };
  questions.forEach(function(question, index) {
    if (question.measure === "IE") {
      if (question.score) {
        results.IE = true;
      }
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            results.I = results.I + 2;
            break;
          case 2:
            results.I = results.I + 1;
            break;
          case 4:
            results.E = results.E + 1;
            break;
          case 5:
            results.E = results.E + 2;
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            results.I = results.I + 2;
            break;
          case 4:
            results.I = results.I + 1;
            break;
          case 2:
            results.E = results.E + 1;
            break;
          case 1:
            results.E = results.E + 2;
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "SN") {
      if (question.score) {
        results.SN = true;
      }
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            results.S = results.S + 2;
            break;
          case 2:
            results.S = results.S + 1;
            break;
          case 4:
            results.N = results.N + 1;
            break;
          case 5:
            results.N = results.N + 2;
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            results.S = results.S + 2;
            break;
          case 4:
            results.S = results.S + 1;
            break;
          case 2:
            results.N = results.N + 1;
            break;
          case 1:
            results.N = results.N + 2;
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "FT") {
      if (question.score) {
        results.FT = true;
      }
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            results.T = results.T + 2;
            break;
          case 2:
            results.T = results.T + 1;
            break;
          case 4:
            results.F = results.F + 1;
            break;
          case 5:
            results.F = results.F + 2;
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            results.T = results.T + 2;
            break;
          case 4:
            results.T = results.T + 1;
            break;
          case 2:
            results.F = results.F + 1;
            break;
          case 1:
            results.F = results.F + 2;
            break;
          default:
            break;
        }
      }
    }

    if (question.measure === "JP") {
      if (question.score) {
        results.JP = true;
      }
      if (question.operator === "+") {
        switch (question.score) {
          case 1:
            results.J = results.J + 2;
            break;
          case 2:
            results.J = results.J + 1;
            break;
          case 4:
            results.P = results.P + 1;
            break;
          case 5:
            results.P = results.P + 2;
            break;
          default:
            break;
        }
      } else if (question.operator === "-") {
        switch (question.score) {
          case 5:
            results.J = results.J + 2;
            break;
          case 4:
            results.J = results.J + 1;
            break;
          case 2:
            results.P = results.P + 1;
            break;
          case 1:
            results.P = results.P + 2;
            break;
          default:
            break;
        }
      }
    }
  });
  return results;
}

export default updateResults;
