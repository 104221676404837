import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import TypeIndicator from "./TypeIndicator";

function TypeIndicators() {
  const { state } = useContext(TestContext);
  let typeCounter = 0;
  state.typeNames.forEach(type => {
    if (state.types[type]) typeCounter++;
  });
  const heading =
    typeCounter > 1 ? "Your Personality Types" : "Your Personality Type";
  return (
    <div className="pb-3 pl-8 pr-16 pt-10">
      <h2 className="flex w-full text-xl font-heading font-bold justify-center pb-5 text-indigo-700">
        {heading}
      </h2>
      <div className="w-full ">
        <div className="flex flex-wrap -mx-1">
          {state.typeNames.map(function(type, index) {
            return (
              <div className="w-full lg:w-1/4 px-1 mb-2" key={index}>
                <TypeIndicator name={type} highlighted={state.types[type]} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TypeIndicators;
