import React from "react";
import DonutChart from "./DonutChart";

function TypeIndicator(props) {
  return (
    <div>
      <div className=" lg:flex w-full justify-center font-sans bg-white font-bold text-2xl rounded border border-gray-200 pb-4">
        <DonutChart preferences={props.preferences} />
      </div>
    </div>
  );
}

export default TypeIndicator;
