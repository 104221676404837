import React, { useReducer } from "react";
import data from "../data/questions.json";
import updateResults from "../functions/updateResults";
import updateTypes from "../functions/updateTypes";
import updatePreferences from "../functions/updatePreferences";
import updateQuestions from "../functions/updateQuestions";

const questions = [...data.questions];

const initialState = {
  currentQuestion: 0,
  answerFilter: "all",
  questions: questions,
  results: {
    I: 0,
    E: 0,
    S: 0,
    N: 0,
    F: 0,
    T: 0,
    J: 0,
    P: 0,
    IE: false,
    SN: false,
    FT: false,
    JP: false
  },
  types: {
    ISTJ: false,
    ISFJ: false,
    INFJ: false,
    INTJ: false,
    ISTP: false,
    ISFP: false,
    INFP: false,
    INTP: false,
    ESTP: false,
    ESFP: false,
    ENFP: false,
    ENTP: false,
    ESTJ: false,
    ESFJ: false,
    ENFJ: false,
    ENTJ: false
  },
  typeNames: [
    "ISTJ",
    "ISFJ",
    "INFJ",
    "INTJ",
    "ISTP",
    "ISFP",
    "INFP",
    "INTP",
    "ESTP",
    "ESFP",
    "ENFP",
    "ENTP",
    "ESTJ",
    "ESFJ",
    "ENFJ",
    "ENTJ"
  ],
  preferences: [
    {
      preference1: "Introversion",
      preference2: "Extraversion",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "IE",
      show: false
    },
    {
      preference1: "Intuition",
      preference2: "Sensing",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "SN",
      show: false
    },
    {
      preference1: "Feeling",
      preference2: "Thinking",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "FT",
      show: false
    },
    {
      preference1: "Perception",
      preference2: "Judging",
      score1: 1,
      score2: 1,
      highlighted1: false,
      highlighted2: false,
      abbreviation: "JP",
      show: false
    }
  ]
};
const TestContext = React.createContext(initialState);

let reducer = (state, action) => {
  switch (action.type) {
    case "update answerFilter":
      return { ...state, answerFilter: action.filter };
    case "update score":
      const score = parseInt(action.score);
      let questions = state.questions;
      questions[state.currentQuestion].score = score;
      const updatedQuestions = updateQuestions(questions);
      const results = updateResults(updatedQuestions);
      const currentQuestion = state.currentQuestion + 1;
      const types = updateTypes(currentQuestion, results);
      const preferences = updatePreferences(results);

      return {
        ...state,
        currentQuestion: currentQuestion,
        questions: updatedQuestions,
        results: results,
        types: types,
        preferences: preferences
      };
    case "restart":
      return initialState;
    default:
      return;
  }
};

function TestProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TestContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TestContext.Provider>
  );
}

export { TestContext, TestProvider };
