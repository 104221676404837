import React from "react";

function TypeIndicator(props) {
  const textColor = props.highlighted
    ? "text-white bg-indigo-700"
    : "text-gray-800 bg-white";
  return (
    <div
      className={
        textColor +
        " lg:flex w-full justify-center font-sans font-bold text-2xl rounded border border-gray-200 pt-8 pb-8"
      }
    >
      {props.name}
    </div>
  );
}

export default TypeIndicator;
