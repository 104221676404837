import React from "react";
import Score from "./Score";

function Answer(props) {
  return (
    <div className=" flex w-full justify-center font-regular bg-white text-base text-gray-800 border-t border-l border-r border-gray-200 pt-4 pb-4 px-6">
      <div className="w-1/4">{props.question.alternative1}</div>
      <div className="w-1/4 flex items-center">
        <Score score={props.question.score} />
      </div>
      <div className="w-1/4">{props.question.alternative2}</div>
      <div className="flex justify-between w-1/4">
        <div className="flex justify-start">{props.question.preference}</div>
        <div className="flex justify-end">{props.question.preferenceScore}</div>
      </div>
    </div>
  );
}

export default Answer;
