import React from "react";
import logo from "../images/bh-logo-grey.gif";

const SmallScreen = () => {
  return (
    <div className="">
      <h1 className="w-full text-2xl font-heading font-bold text-center text-indigo-700 pt-10 pb-16">
        Personality Test
      </h1>
      <div className="w-full px-10 font-heading font-bold text-lg text-center text-gray-700 pb-64">
        A larger display is required for this application.
      </div>
      <footer className="flex w-full justify-center pb-16">
        <div className="w-16">
          <a href="https://bob-humphrey.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default SmallScreen;
