import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";

function QuestionForm() {
  const { state, dispatch } = useContext(TestContext);
  if (state.currentQuestion < 32) return null;
  return (
    <div className="lg-flex w-full bg-gray-50 pb-6">
      <div className="text-indigo-700 lg:flex w-full text-xl font-heading font-bold justify-center mt-8 mb-5">
        You've Completed the Test
      </div>
      <div className="flex justify-center w-full">
        <div
          className="text-gray-500 hover:text-white hover:bg-gray-500 border-gray-500 cursor-pointer font-regular font-bold text-center rounded py-2 px-4 mx-2 border"
          onClick={() => {
            dispatch({
              type: `restart`
            });
          }}
        >
          Try Again
        </div>
      </div>
    </div>
  );
}

export default QuestionForm;
