import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import Answer from "./Answer";
import AnswerFilterForm from "./AnswerFilterForm";

const Answers = props => {
  const { state } = useContext(TestContext);
  if (state.currentQuestion === 0) return null;
  return (
    <div className="w-full px-16 pt-16">
      <div className="w-full">
        <div className="lg:flex w-full text-xl font-heading font-bold justify-center mb-5 text-indigo-700">
          Your Answers
        </div>
      </div>
      <AnswerFilterForm />
      <div className=" flex w-full justify-center font-sans text-sm font-bold uppercase text-gray-600 bg-gray-100 border-l border-t border-r border-gray-200 px-6 py-3">
        <div className="w-1/4">Description</div>
        <div className="w-1/4 flex items-center pl-12">Your Choice</div>
        <div className="w-1/4">Description</div>
        <div className="flex justify-between w-1/4">
          <div className="flex justify-start">Preference</div>
          <div className="flex justify-end">Points</div>
        </div>
      </div>
      <div className="w-full border-b border-gray-200">
        {state.questions.map(function(question, index) {
          if (question.score > 0 && state.answerFilter === "all") {
            return (
              <div className="flex w-full" key={index}>
                <Answer question={question} />
              </div>
            );
          } else if (
            question.score > 0 &&
            state.answerFilter === question.measure
          ) {
            return (
              <div className="flex w-full" key={index}>
                <Answer question={question} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default Answers;
