import React, { useContext } from "react";
import { TestContext } from "../context/TestContext";
import QuestionAlternative from "./QuestionAlternative";
import QuestionFormButton from "./QuestionFormButton";

function QuestionForm() {
  const { state } = useContext(TestContext);
  if (state.currentQuestion > 31) return null;
  return (
    <div className="lg-flex bg-gray-50 pt-4 pb-5">
      <h2 className="lg:flex w-full text-xl font-heading font-bold justify-center mt-4 mb-5 text-indigo-700">
        Describe Yourself
      </h2>
      <div className="lg:flex w-full mb-8">
        <div className="lg:flex w-1/4">
          <QuestionAlternative
            alternative={state.questions[state.currentQuestion].alternative1}
            location="left"
          />
        </div>
        <div className="lg:flex w-1/2">
          <QuestionFormButton choice="1" />
          <QuestionFormButton choice="2" />
          <QuestionFormButton choice="3" />
          <QuestionFormButton choice="4" />
          <QuestionFormButton choice="5" />
        </div>
        <div className="lg:flex w-1/4 pl-4">
          <QuestionAlternative
            alternative={state.questions[state.currentQuestion].alternative2}
            location="right"
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionForm;
