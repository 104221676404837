import React from "react";
import logo from "../images/bh-logo-grey.gif";

const Footer = () => {
  return (
    <footer className="flex w-full justify-center pb-16">
      <div className="w-16">
        <a href="https://bob-humphrey.com">
          <img src={logo} alt="Logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
